<template>
	<div class='h-card-wrap'>
		<div class='h-card-image link' @click='pushHotel(item.code)'>
			<img :src="`${item.image.indexOf('http') ? `https://tcc.ua${item.image}` : item.image}`">
		</div>
		<div class='h-card-container'>
			<div class='h-card-content'>
				<ul class='h-card-stars-list'>
					<li class='h-card-stars-item' v-for='(item, i) in item.categorySimpleCode' :key='i'>
						<v-icon icon='star' size='10' />
					</li>
				</ul>
				<p class='h-card-title link' @click='pushHotel(item.code)'>{{item.name}}</p>
				<div class="h-card-info">
					<a class='a-text-with-icon app-link' style='font-weight: 400 !important' :href='item.place' target="_blank">
						<span>{{item.address}}, {{item.postalCode}} {{item.countryName}}</span>
					</a>
				</div>
			</div>
			<div class="h-card-group-buttons">
				<div class="col">
					<v-button xxsmall outline color='green' class='h-card-group-button favourite app-btn-active favourite-active'
						@click='hotelFavoriteUnchecked'
						:loading='loading_btn'
					>
						<v-icon icon='favourite' size='14' />
						<span class='desktop-show'>{{$t('account.buttons.remove')}}</span>
						<span class='desktop-hide'>{{$t('account.comparison.remove_from_favorites')}}</span>
					</v-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { hotelFavoriteUnchecked } from '@/services/request';

	export default {
		data: () => ({
			loading_btn: false
		}),
		props: ["item"],
		computed: {
			source() {
				return JSON.parse(this.$route.query.source)
			}
		},
		methods: {
			hotelFavoriteUnchecked() {
				this.loading_btn = true;

				hotelFavoriteUnchecked(this.item.code)
					.then(() => this.$emit("update-favourite"))
					.finally(() => this.loading_btn = false)
			},
			pushHotel(code) {
				return this.$router.push({
					name: "hotels-result",
					params: {code: code, lang: this.$route.params.lang},
					query: {storageId: this.$route.params.storageId, source: this.$route.query.source}
				})
			},
			discount(offer) {
				return `-${Math.ceil(100 - offer.amount * 100 / (offer.amount + offer.discount.amount))}%`
			}
		}
	}
</script>
