<template>
	<div>
		<div class="a-favourite">
			<div class="app-container">
				<div class="a-favourite-header">
					<p class="a-heading-1">{{ $t('routes.favourite') }}</p>
				</div>

				<div class="a-favourite-preloader" v-if="!loading && initialItems.length === 0">
					<div>
						<div class="a-favourite-preloader-icons">
							<v-icon
								class="a-favourite-preloader-icon big"
								icon="favourite"
								:size="156"
							/>
							<v-icon
								class="a-favourite-preloader-icon small"
								icon="favourite"
								:size="87"
							/>
						</div>
						<p class="a-heading-1 a-favourite-preloader-title">
							{{ $t('account.no_favorites') }}
						</p>
						<p class="a-favourite-preloader-text">
							{{ $t('account.unfortunately_there_are_no_selected_hotels') }}
						</p>
					</div>
				</div>
				<div class="a-favourite-container row" v-else>
					<div class="a-favourite-side col-auto">
						<ul>
							<li v-for="(group, i) in items" :key="i" :class="{'active': activeIndex === i}">
								<h4 class="heading-4" @click="activeIndex = i">{{ i }}</h4>
							</li>
						</ul>

					</div>
					<div class="a-favourite-box col">
						<template v-if="items[activeIndex]">
							<trips-card
								v-for="(item, i) in items[activeIndex]"
								:key="i"
								:item="item"
								@update-favourite="hotelFavorite"
							/>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TripsCard from '@/views/account/favourite/card-hotel';
import {hotelFavorite} from '@/services/request';

export default {
	data: () => ({
		initialItems: [],
		items: [],
		loading: true,
		activeIndex: null,
	}),
	components: {
		TripsCard,
	},
	created() {
		this.hotelFavorite();
	},
	methods: {
		hotelFavorite() {
			this.loading = true;
			hotelFavorite({
				search: this.search,
			})
				.then(res => {
					this.initialItems = res.data;
					this.items = this.$_.groupBy(res.data, 'countryName');
					this.activeIndex = Object.keys(this.items)[0]
				})
				.finally(() => (this.loading = false));
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/global/variables';
@import '@/styles/global/mixins';

.a-favourite {
  padding: 30px 0 80px;
  display: grid;
  min-height: calc(100vh - 70px);
  @include minw($grid-breakpoints-sm) {
    padding: 80px 0;
    min-height: calc(100vh - 105px * 0.8);
  }
  @include minw($grid-breakpoints-md) {
    min-height: calc(100vh - 105px);
  }
	&-container {
		height: 100%;
	}

	&-side {
		ul {
			border-radius: 30px;

			min-width: 250px;
			padding: 10px;
			background-color: #ffffff;
			box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);

			li {
				margin: 10px;
				cursor: pointer;
				&:hover{
					color: #1552d5;
				}
				&.active {
					color: #1552d5;
				}
			}
		}
	}

	&-box {
		.h-card-wrap {
			margin-bottom: 25px;
			padding: 0;
			background-color: #ffffff;
			box-shadow: 0 0 20px rgba(86,86,86,.2);
			border-radius: 30px;
		}
	}
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    @include maxw($grid-breakpoints-xs - 1) {
      justify-content: center;
    }
    .app-icon {
      flex-shrink: 0;
      margin-right: 12px;
      svg {
        fill: none;
        stroke: currentColor;
      }
    }
  }
  &-content {
    .app-accordion {
      &__title {
        @include minw($grid-breakpoints-lg) {
          padding: 23px 30px;
        }
        .heading-4 {
          display: flex;
          align-items: center;
          @include minw($grid-breakpoints-lg) {
            font-size: 18px;
          }
          .app-icon {
            width: 20px !important;
            height: 20px !important;
            flex-shrink: 0;
            margin-right: 25px;
            color: $green-persian;
            transition: transform $transition;
          }
        }
      }
      &__trigger_active {
        .app-icon {
          transform: rotate(180deg);
        }
      }
      &__content {
        padding: 20px;
        @include minw($grid-breakpoints-xs) {
          padding: 0;
        }
      }
      &__item {
        box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
        margin-bottom: 15px;
      }
    }
    .h-card {
      &-wrap {
        &:last-child {
          .h-card-container {
            border: none;
          }
        }
        @include maxw($grid-breakpoints-xs - 1) {
          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }
      }
      &-container {
        @include maxw($grid-breakpoints-xs - 1) {
          padding: 15px 0 25px;
        }
      }
      &-image {
        height: 195px;
        border-radius: 5px;
        @include minw($grid-breakpoints-xs) {
          width: 180px;
          height: 120px;
        }
      }
    }
  }
  &-preloader {
    padding: 40px 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid rgba($black, 0.1);
    &-icons {
      width: 156px;
      height: 156px;
      margin: auto;
      margin-bottom: 45px;
    }
    &-icon {
      &.big {
        color: $green-persian;
      }
      &.small {
        position: absolute;
        bottom: 0;
        right: -10px;
        color: $cyan-light;
      }
    }
    &-title {
      margin-bottom: 16px;
    }
    &-text {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .app-accordion__content {
    @include minw($grid-breakpoints-xs) {
      padding: 12px 6px;
    }
  }
}
</style>
